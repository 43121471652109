import { formatNumber } from '@slideslive/fuse-kit/utils';

const OPTIONS = {
  USD: {
    symbol: '$',
    html_entity: '$',
    symbol_first: true,
    decimal_mark: '.',
    thousands_separator: ',',
    withoutTax: '',
  },
  CZK: {
    symbol: 'Kč',
    html_entity: 'Kč',
    symbol_first: false,
    decimal_mark: ',',
    thousands_separator: ' ',
    withoutTax: 'bez DPH',
  },
  EUR: {
    symbol: '€',
    html_entity: '&#x20AC;',
    symbol_first: true,
    decimal_mark: ',',
    thousand_separator: '.',
    withoutTax: '',
  },
  CHF: {
    symbol: 'CHF',
    html_entity: 'CHF',
    symbol_first: false,
    decimal_mark: ',',
    thousand_separator: '.',
    withoutTax: '',
  },
  GBP: {
    symbol: 'GBP',
    html_entity: 'GBP',
    symbol_first: false,
    decimal_mark: ',',
    thousand_separator: '.',
    withoutTax: '',
  },
  CAD: {
    symbol: 'CAD',
    html_entity: 'CAD',
    symbol_first: false,
    decimal_mark: ',',
    thousand_separator: '.',
    withoutTax: '',
  },
  AUD: {
    symbol: 'AUD',
    html_entity: 'AUD',
    symbol_first: false,
    decimal_mark: '.',
    thousand_separator: ' ',
    withoutTax: '',
  },
};

const LOCALES = {
  CZK: {
    en: {
      symbol: 'CZK',
    },
    de: {
      symbol: 'CZK',
    },
  },
};

// eslint-disable-next-line no-unused-vars
const TAXES = {
  CZ: 0.21,
  DE: 0.19,
};

const EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

class Money {
  constructor(cents, currency = 'CZK') {
    this.cents = cents;
    this.currency = currency;
  }

  format(round, locale) {
    if (!round) {
      round = 'auto';
    }

    const options = OPTIONS[this.currency];
    if (options == null) {
      console.warn(`No money options for currency ${this.currency}`);
    }

    let sign = '';
    if (this.cents < 0) {
      sign = '-';
    }

    if (round === 'auto') {
      if (this.cents % 100 === 0) {
        round = 0;
      } else {
        round = 2;
      }
    } else if (round < 0) {
      round *= -1;
    }

    let result = '';

    if (options.symbol_first) {
      if (locale != null && this.currency in LOCALES && locale in LOCALES[this.currency]) {
        result += LOCALES[this.currency][locale].symbol;
      } else {
        result += options.symbol;
      }
    }

    result += sign;
    result += formatNumber(
      (Math.abs(this.cents) / 100.0).toFixed(round),
      options.thousands_separator,
      options.decimal_mark,
    );

    if (!options.symbol_first) {
      if (locale != null && this.currency in LOCALES && locale in LOCALES[this.currency]) {
        result += ` ${LOCALES[this.currency][locale].symbol}`;
      } else {
        result += ` ${options.symbol}`;
      }
    }

    return result;
  }

  exchangeTo(currency, date) {
    if (currency === this.currency) {
      return this;
    }

    let fromBaseRate;
    let toBaseRate;

    if (date != null) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (
        gon.exchange_rates &&
        gon.exchange_rates[year] &&
        gon.exchange_rates[year][month] &&
        gon.exchange_rates[year][month][day]
      ) {
        fromBaseRate = gon.exchange_rates[year][month][day][this.currency];
        toBaseRate = gon.exchange_rates[year][month][day][currency];
      }

      if (!fromBaseRate) {
        console.warn(`From base rate undefined for ${date}. Using default.`);
      }

      if (!toBaseRate) {
        console.warn(`To base rate undefined for ${date}. Using default.`);
      }
    }

    if (!fromBaseRate) {
      fromBaseRate = gon.default_exchange_rates[this.currency];
    }

    if (!toBaseRate) {
      toBaseRate = gon.default_exchange_rates[currency];
    }

    const rate = fromBaseRate / toBaseRate;
    const cents = Math.round(this.cents * rate);

    return new Money(cents, currency);
  }

  plus(other, date) {
    return new Money(this.cents + other.exchangeTo(this.currency, date).cents, this.currency);
  }

  minus(other, date) {
    return new Money(this.cents - other.exchangeTo(this.currency, date).cents, this.currency);
  }

  vatForSupplierAndClient(supplierCountry, clientCountry, clientState, clientVatId, date) {
    const taxPct = Money.vatPctForSupplierAndClient(supplierCountry, clientCountry, clientState, clientVatId, date);
    const cents = Money.roundedTaxPct(this.cents, this.currency, taxPct);
    return new Money(cents, this.currency);
  }

  currencyInfo(currency, locale) {
    let info = OPTIONS[currency];
    if (locale && currency in LOCALES && locale in LOCALES[currency]) {
      info = Object.assign(info, LOCALES[currency][locale]);
    }

    return info;
  }

  static roundedTaxPct(cents, currency, taxPct) {
    return Money.roundedTax(cents * taxPct, currency);
  }

  // eslint-disable-next-line no-unused-vars
  static roundedTax(tax, currency) {
    return (tax / 100.0).toFixed(2) * 100;
  }

  static vatPctForSupplierAndClient(supplierCountry, clientCountry, clientState, clientVatId, date) {
    let vatPctForSupplierCountry;
    if (supplierCountry === 'CZ' || supplierCountry === 'DE') {
      vatPctForSupplierCountry = Money.vatPctForSupplierCountry(supplierCountry, date);

      if (supplierCountry === clientCountry) {
        return vatPctForSupplierCountry;
      }

      if (EU_COUNTRIES.indexOf(clientCountry) < 0 && clientCountry !== 'CH') {
        return vatPctForSupplierCountry;
      }

      if (!Money.isValidVatNumber(clientVatId, clientCountry)) {
        return vatPctForSupplierCountry;
      }
    }

    return 0;
  }

  static vatPctForSupplierCountry(country, date) {
    if (country === 'CZ') return 0.21;

    if (country === 'DE') {
      if (date.getFullYear() === 2020) {
        if (date.getMonth() >= 6 && date.getMonth() <= 11) {
          return 0.16;
        }
      }

      return 0.19;
    }

    if (country === 'US') {
      return 0;
    }

    console.warn('Unsupported country for VAT!');
    return undefined;
  }

  static isValidVatNumber(vatNumber, country) {
    if (EU_COUNTRIES.indexOf(country) >= 0 || country === 'CH') {
      return vatNumber.toUpperCase().startsWith(Money.vatNumberPrefixForCountry(country));
    }

    return vatNumber.trim() !== '';
  }

  static vatNumberPrefixForCountry(country) {
    if (country === 'CH') {
      return 'CHE';
    }

    if (country === 'GR') {
      return 'EL';
    }

    if (EU_COUNTRIES.indexOf(country) >= 0) {
      return country;
    }

    return '';
  }
}

export default Money;
